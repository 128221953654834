<template>
    <div id="calls">

        <!--## Blocco tabella miei ultimi concorsi ##-->
        <div class="card">
            <div class="card-body">
                <h5 class="header-title mb-4 text-uppercase"><i class="mdi mdi-school-outline font-size-24 mr-1 align-middle"></i> {{$t('calls.bandiInScadenza')}}</h5>

                <div class="row align-items-center mb-2">
                    <div class="col-md-4">
                        <div class="d-inline">
                            <label class="switch mb-0">
                                <input type="checkbox" v-model="onlyFeatured">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div class="d-inline ml-2">
                            <span>{{$t('calls.soloPrimoPiano')}}</span>
                        </div>
                    </div>
                    <div class="col-md-4 offset-md-4 text-right">
                        <div class="input-group">
                            <input type="text" id="input-search" :class="[((searchKeyWord !== '') ? 'border-right-0' : ''), 'form-control']" @focusin="changeBorderColorDelete('#b1bbc4')" @focusout="changeBorderColorDelete('#ced4da')" v-model="searchKeyWord" :placeholder="$t('calls.cercaUnBando')" aria-describedby="basic-addon2">
                            <div class="input-group-append" v-if="searchKeyWord !== ''">
                                <span class="icon-container input-group-text bg-transparent border-left-0 cursorPointer" @click="searchKeyWord = ''" style="border-color: #b1bbc4;" id="delete-search-icon"><i class="mdi mdi-close"></i></span>
                            </div>
                            <div class="dropdown ml-2" id="dropdownMenu">
                                <button class="btn btn-primary dropdown-toggle" type="button" data-display="static" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="mdi mdi-filter"></i>
                                </button>
                                <div
                                     class="dropdown-menu dropdown-menu-right"
                                     aria-labelledby="dropdownMenu2"
                                     style="max-width: 500px;z-index: 999; overflow-y: auto"
                                     v-bind:style="{'max-height': dropdownAvailableSpace}"
                                >
                                    <h6 class="dropdown-header">{{$t('calls.titoloStudio')}}</h6>
                                    <div class="dropdown-item-text text-wrap">
                                        <span class="custom-text-filters mr-2">
                                            <input type="checkbox" v-model="allStudyTitle" id="allStudyTitle" :readonly="allStudyTitle" class="cursorPointer"><span class="pl-1 my-auto cursorPointer" @click="triggerCheckbox('allStudyTitle', allStudyTitle)">{{$t('calls.tutti')}}</span>
                                        </span>
                                        <span class="custom-text-filters mr-2" v-for="title in filters.studyTitle">
                                            <input type="checkbox" v-model="searchFilters.studyTitle[title]" class="cursorPointer"><span class="pl-1 my-auto cursorPointer" @click="triggerCheckbox('studyTitle', title)">{{title}}</span>
                                        </span>
                                    </div>

                                    <div class="dropdown-divider"></div>

                                    <h6 class="dropdown-header">{{$t('calls.settore')}}</h6>
                                    <div class="dropdown-item-text text-wrap">
                                        <span class="custom-text-filters mr-2">
                                            <input type="checkbox" v-model="allSectors" id="allSectors" :readonly="allSectors" class="cursorPointer"><span class="pl-1 my-auto cursorPointer" @click="triggerCheckbox('allSectors', allSectors)">{{$t('calls.tutte')}}</span>
                                        </span>
                                        <span class="custom-text-filters mr-2" v-for="sector in filters.sectors">
                                            <input type="checkbox" v-model="searchFilters.sectors[sector]" class="cursorPointer"><span class="pl-1 my-auto cursorPointer" @click="triggerCheckbox('sectors', sector)">{{sector}}</span>
                                        </span>
                                    </div>

                                    <div class="dropdown-divider"></div>

                                    <h6 class="dropdown-header">{{$t('calls.areaGeografica')}}</h6>
                                    <div class="dropdown-item-text text-wrap">
                                        <span class="custom-text-filters mr-2">
                                            <input type="checkbox" v-model="allGeographicArea" id="allGeographicArea" :readonly="allGeographicArea" class="cursorPointer"><span class="pl-1 my-auto cursorPointer" @click="triggerCheckbox('allGeographicArea', allGeographicArea)">{{$t('calls.tutte')}}</span>
                                        </span>
                                        <span class="custom-text-filters mr-2" v-for="area in filters.geographicArea">
                                            <input type="checkbox" v-model="searchFilters.geographicArea[area]" class="cursorPointer"><span class="pl-1 my-auto cursorPointer" @click="triggerCheckbox('geographicArea', area)">{{area}}</span>
                                        </span>
                                    </div>

                                    <div class="dropdown-divider"></div>

                                    <h6 class="dropdown-header">{{$t('calls.tipologiaEnti')}}</h6>
                                    <div class="dropdown-item-text text-wrap">
                                        <span class="custom-text-filters mr-2">
                                            <input type="checkbox" v-model="allTipologiaEnti" id="allTipologiaEnti" :readonly="allTipologiaEnti" class="cursorPointer"><span class="pl-1 my-auto cursorPointer" @click="triggerCheckbox('allTipologiaEnti', allTipologiaEnti)">{{$t('calls.tutte')}}</span>
                                        </span>
                                        <span class="custom-text-filters mr-2" v-for="ente in filters.tipologiaEnti">
                                            <input type="checkbox" v-model="searchFilters.tipologiaEnti[ente]" class="cursorPointer"><span class="pl-1 my-auto cursorPointer" @click="triggerCheckbox('tipologiaEnti', ente)">{{ente}}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2" id="filters-badge-row" v-if="filtersCountStudyTitle > 0 || filtersCountSectors > 0 || filtersCountGeographicArea > 0 || filtersCountTipologiaEnti > 0">
                    <div class="col-12 text-right">
                        <div class="badge badge-soft-dark mr-2" style="font-size: 15px" v-if="filtersCountStudyTitle > 0">{{$t('calls.titoloStudio')}} ({{filtersCountStudyTitle}}) <i @click="allStudyTitle = true" class="mdi mdi-close cursorPointer"></i></div>
                        <div class="badge badge-soft-dark mr-2" style="font-size: 15px" v-if="filtersCountSectors > 0">{{$t('calls.settore')}} ({{filtersCountSectors}}) <i @click="allSectors = true" class="mdi mdi-close cursorPointer"></i></div>
                        <div class="badge badge-soft-dark" style="font-size: 15px" v-if="filtersCountGeographicArea > 0">{{$t('calls.areaGeografica')}} ({{filtersCountGeographicArea}}) <i @click="allGeographicArea = true" class="mdi mdi-close cursorPointer"></i></div>
                        <div class="badge badge-soft-dark" style="font-size: 15px" v-if="filtersCountTipologiaEnti > 0">{{$t('calls.tipologiaEnti')}} ({{filtersCountTipologiaEnti}}) <i @click="allTipologiaEnti = true" class="mdi mdi-close cursorPointer"></i></div>

                    </div>
                </div>

                <template v-if="callsFiltered.length > 0">
                    <div class="table-responsive">
                        <b-table tbody-tr-class="cursorPointer" head-variant="light"
                                 :per-page="perPage"
                                 :current-page="currentPage"
                                 :fields="fields"
                                 :items="callsFiltered"
                                 @row-clicked="openCallDetail">
                            <!-- Head table -->
                                <template v-slot:head(scadenza)="data">
                                    <div>{{$t("calls.scadenza")}}</div>
                                </template>
                                <template v-slot:head(nome)="data">
                                    <div>{{$t('calls.nome')}}</div>
                                </template>
                                <template v-slot:head(primoPiano)="data">
                                    <div class="text-center"></div>
                                </template>
                            <!-- /Head table -->

                            <template v-slot:cell(scadenza)="data">
                                <span class="text-success font-weight-semibold">{{formatData(data.item.startDate, 'DD MMM')}}</span> - <span class="text-danger">{{formatData(data.item.endDate, 'DD MMM')}}</span>
                            </template>

                            <template v-slot:cell(nome)="data">
                                <div>
                                    <span class="font-weight-semibold">{{data.item.name}}</span>
                                </div>
                            </template>

                            <template v-slot:cell(primoPiano)="data">
                                <div class="text-center">
                                    <span class="badge badge-danger" v-if="data.item.featured === 'yes'">{{$t('calls.primoPiano')}}</span>
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <div class="mt-4">
                        <div class="pagination pagination-rounded justify-content-center mb-0">
                            <b-pagination v-model="currentPage" :per-page="perPage" pills :total-rows="callsFiltered.length"></b-pagination>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center">{{$t('calls.nonCiSonoBandiInScandeza')}}</div>
                </template>

                <!-- Modal paths request error -->
                <div id="modalCallsRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalCallsRequestsErrorLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="modalCallsRequestsErrorLabel">{{$t('calls.modale.errore.titolo')}}</h5>
                            </div>
                            <div id="modal-body-calls-requests-error" class="modal-body">
                                <p id="message-body-calls-requests-error"></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" id="button-ok-paths" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesEnum.HOME })">{{$t('generale.annulla')}}</button>
                                <button type="button" id="button-retry-paths" class="btn btn-primary waves-effect waves-light" @click="getCalls()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal paths request error -->

                <!-- Modal call detail -->
                <div id="modalCallsDetail" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalCallsDetailLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="w-100 text-center modal-title">
                                    <span class="font-weight-semibold h5" v-if="competitionEnd !== ''">{{$t('calls.ilConcorsoScadeTra')}}: {{competitionEnd}}</span>
                                    <span class="font-weight-semibold h5" v-else>{{$t('calls.concorsoScaduto')}}</span>
                                </div>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div id="modal-body-calls-detail" class="modal-body">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12">
                                            <!-- First block -->
                                            <div class="card shadow">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <img :src="callDetail.image_url" class="card-img-top">
                                                        </div>
                                                        <div class="col-8">
                                                            <h5 class="card-title">{{callDetail.name}}</h5>
                                                            <span class="pt-2" v-html="callDetail.details"></span>
                                                        </div>
                                                    </div>
                                                    <div class="row pt-3">
                                                        <div class="col-6 text-center border-right-1-grey">
                                                            <i class="mdi mdi-calendar-range text-success font-icon"></i>
                                                            <div class="font-weight-semibold">{{formatData(callDetail.startDate, 'DD-MM-YYYY')}}</div>
                                                        </div>
                                                        <div class="col-6 text-center">
                                                            <i class="mdi mdi-calendar-range text-danger font-icon"></i>
                                                            <div class="font-weight-semibold">{{formatData(callDetail.endDate, 'DD-MM-YYYY')}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /First block -->

                                            <!-- Second block -->
                                            <div class="card shadow">
                                                <div class="row p-3">
                                                    <div class="col-4 text-center border-right-1-grey">
                                                        <i class="mdi mdi-school font-icon"></i>
                                                        <div>
                                                            <span class="font-weight-semibold" v-if="callDetail.study_title !== undefined">{{callDetail.study_title.value}}</span>
                                                            <span class="font-weight-semibold" v-else>-</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 text-center border-right-1-grey">
                                                        <i class="mdi mdi-bank font-icon"></i>
                                                        <div>
                                                            <span class="font-weight-semibold" v-if="callDetail.organizer_name !== undefined">{{callDetail.organizer_name}}</span>
                                                            <span class="font-weight-semibold" v-else>-</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <i class="mdi mdi-map-marker font-icon"></i>
                                                        <div>
                                                            <span class="font-weight-semibold" v-if="callDetail.location_name !== undefined">{{callDetail.location_name}}</span>
                                                            <span class="font-weight-semibold" v-else>-</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /Second block -->

                                            <!-- Third block -->
                                            <div class="row pt-3">
                                                <div class="col text-center cursorPointer" :key="`${callDetail.id}-guidaAllaPartecipazione`" v-if="callDetail.guide_partecipation !== undefined"  @click="openLink(callDetail.guide_partecipation.valueL)">
                                                    <IconaBando :key="`${callDetail.id}-icona-guidaAllaPartecipazione`" v-bind:img-url='getImagePath("guidaAllaPartecipazione.png")'></IconaBando>
                                                    <div><span>{{$t('calls.guidaAllaPartecipazione')}}</span></div>
                                                </div>
                                                <div class="col text-center cursorPointer" :key="`${callDetail.id}-guidaAlloStudio`" v-if="callDetail.guide_study !== undefined" @click="openLink(callDetail.guide_study.valueL)">
                                                    <IconaBando :key="`${callDetail.id}-icona-guidaAlloStudio`" v-bind:img-url='getImagePath("guidaAlloStudio.png")'></IconaBando>
                                                    <div><span>{{$t('calls.guidaAlloStudio')}}</span></div>
                                                </div>
                                                <div class="col text-center cursorPointer" :key="`${callDetail.id}-corsiOnline`" v-if="callDetail.online_curse !== undefined" @click="openLink(callDetail.online_curse.valueL)">
                                                    <IconaBando :key="`${callDetail.id}-icona-corsiOnline`" v-bind:img-url='getImagePath("corsoOnline.png")'></IconaBando>
                                                    <div><span>{{$t('calls.corsiOnline')}}</span></div>
                                                </div>
                                                <div class="col text-center cursorPointer" :key="`${callDetail.id}-manuale`" v-if="callDetail.manual !== undefined" @click="openLink(callDetail.manual.valueL)">
                                                    <IconaBando :key="`${callDetail.id}-icona-manuale`" v-bind:img-url='getImagePath("manuale.png")'></IconaBando>
                                                    <div><span>{{$t('calls.manuale')}}</span></div>
                                                </div>
                                                <div class="col text-center cursorPointer" :key="`${callDetail.id}-aggiornamentiViaBot`" v-if="callDetail.update !== undefined" @click="openLink(callDetail.update.valueL)">
                                                    <IconaBando :key="`${callDetail.id}-icona-aggiornamentiViaBot`" v-bind:img-url='getImagePath("aggiornamentiBot.png")'></IconaBando>
                                                    <div><span>{{$t('calls.aggiornamentiViaBot')}}</span></div>
                                                </div>
                                                <div class="col text-center cursorPointer" :key="`${callDetail.id}-gruppoFacebook`" v-if="callDetail.study_group !== undefined" @click="openLink(callDetail.study_group.valueL)">
                                                    <IconaBando :key="`${callDetail.id}-icona-gruppoFacebook`" v-bind:img-url='getImagePath("gruppoFacebook.png")'></IconaBando>
                                                    <div><span>{{$t('calls.gruppoFacebook')}}</span></div>
                                                </div>
                                                <div class="col text-center cursorPointer" :key="`${callDetail.id}-gruppoTelegram`" v-if="callDetail.telegram_study_group !== undefined" @click="openLink(callDetail.telegram_study_group.valueL)">
                                                    <IconaBando :key="`${callDetail.id}-icona-gruppoTelegram`" v-bind:img-url='getImagePath("gruppoTelegram.png")'></IconaBando>
                                                    <div><span>{{$t('calls.gruppoTelegram')}}</span></div>
                                                </div>
                                                <div class="col text-center cursorPointer" :key="`${callDetail.id}-paginaUfficiale`" v-if="callDetail.offical_info !== undefined && callDetail.offical_info.value !== undefined && callDetail.offical_info.value !== ''" @click="openLink(callDetail.offical_info.valueL)">
                                                    <IconaBando :key="`${callDetail.id}-icona-paginaUfficiale`" v-bind:img-url='getImagePath("officialPage.png")'></IconaBando>
                                                    <div><span>{{$t('calls.paginaUfficiale')}}</span></div>
                                                </div>
                                                <div class="col text-center cursorPointer" :key="`${callDetail.id}-bando`" v-if="callDetail.bando !== undefined" @click="openLink(callDetail.bando.valueL)">
                                                    <IconaBando :key="`${callDetail.id}-icona-bando`" v-bind:img-url='getImagePath("bando.png")'></IconaBando>
                                                    <div><span>{{$t('calls.bando')}}</span></div>
                                                </div>
                                            </div>
                                            <!-- /Third block -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal call detail -->

            </div>
        </div>
        <!--## Fine Blocco tabella miei ultimi concorsi ##-->

    </div>
</template>

<script>
    import 'bootstrap'
    import $ from 'jquery'
    import {BPagination, BTable} from 'bootstrap-vue'
    import 'bootstrap-vue/dist/bootstrap-vue.css'
    import {routesEnums} from "@/helpers/enums";
    import BandiClient from "@/lib/CustomConcorsandoClient/api/BandiClient";
    import _ from 'lodash';
    import moment from 'moment';
    import { Timer } from 'easytimer.js';
    import IconaBando from "@/components/IconaBando";
    import {v4 as uuidv4} from 'uuid';

    const bandiClient = BandiClient.instance

    export default {
        name: "Calls",
        components: {
            BTable,
            BPagination,
            IconaBando
        },
        data () {
            return {
                callDetail: {},
                calls: [],
                routesEnum: routesEnums,
                perPage: 10,
                currentPage: 1,
                fields: [
                    {
                        key: 'Scadenza',
                        tdClass: 'verticalCenter custom-height-calls custom-width-calls'
                    },
                    {
                        key: 'Nome',
                        tdClass: 'verticalCenter custom-height-calls'
                    },
                    {
                        key: 'primoPiano',
                        tdClass: 'verticalCenter custom-height-calls custom-width-calls'
                    }
                ],
                searchKeyWord: '',
                onlyFeatured: false,
                filters: {
                    studyTitle: ["Licenza media", "Diploma", "Laurea"],
                    sectors: ["Amministrativo - Contabile - Giuridico", "Forze Armate e di Polizia", "Sanitario", "Scuola", "Socio Assistenziale", "Tecnico Scientifico", "Turismo e cultura"],
                    geographicArea: ["Nazionale", "Abruzzo", "Basilicata", "Calabria", "Campania", "Emilia-Romagna", "Friuli-Venezia Giulia", "Lazio", "Liguria", "Lombardia", "Marche", "Molise", "Piemonte", "Puglia", "Sardegna", "Sicilia", "Toscana", "Trentino-Alto-Adige", "Umbria", "Valle D'Aosta", "Veneto"],
                    tipologiaEnti: ["Aziende sanitarie e ospedaliere", "Enti locali", "Ministeri ed altri enti pubblici", "Università", "Centri impiego e Agenzie Regionali per il Lavoro", "Camere di commercio"]
                },
                searchFilters: {
                    studyTitle: {},
                    sectors: {},
                    geographicArea: {},
                    tipologiaEnti: {}
                },
                competitionEnd: '',
                dropdownAvailableSpace: "unset",
            }
        },
        beforeDestroy() {
            this.stopTimer()
        },
        watch: {
            'searchFilters.studyTitle': {
                deep: true,
                handler (newValue, oldValue) {
                    if (_.isEmpty(newValue) || _.find(this.searchFilters.studyTitle, (sT) => sT) === undefined) {
                        if (!this.allStudyTitle) {
                            this.allStudyTitle = true
                        }
                    }
                    this.$nextTick(() => this.updateDropdownAvailableSpace());
                }
            },
            'searchFilters.sectors': {
                deep: true,
                handler (newValue, oldValue) {
                    if (_.isEmpty(newValue) || _.find(this.searchFilters.sectors, (s) => s) === undefined) {
                        if (!this.allSectors) {
                            this.allSectors = true
                        }
                    }
                    this.$nextTick(() => this.updateDropdownAvailableSpace());
                }
            },
            'searchFilters.geographicArea': {
                deep: true,
                handler (newValue, oldValue) {
                    if (_.isEmpty(newValue) || _.find(this.searchFilters.geographicArea, (gA) => gA) === undefined) {
                        if (!this.allGeographicArea) {
                            this.allGeographicArea = true
                        }
                    }
                    this.$nextTick(() => this.updateDropdownAvailableSpace());
                }
            },
            'searchFilters.tipologiaEnti': {
                deep: true,
                handler (newValue, oldValue) {
                    if (_.isEmpty(newValue) || _.find(this.searchFilters.tipologiaEnti, (gA) => gA) === undefined) {
                        if (!this.allTipologiaEnti) {
                            this.allTipologiaEnti = true
                        }
                    }
                    this.$nextTick(() => this.updateDropdownAvailableSpace());
                }
            },
            onlyFeatured: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.$nextTick(() => this.updateDropdownAvailableSpace());
                }
            }
        },
        computed: {
            allStudyTitle: {
                get: function () {
                    return !(!_.isEmpty(this.searchFilters.studyTitle) || _.find(this.searchFilters.studyTitle, (sT) => sT) !== undefined);
                },
                set: function (newValue) {
                    if (newValue) {
                        this.searchFilters.studyTitle = {}
                    }
                }
            },
            allSectors: {
                get: function () {
                    return !(!_.isEmpty(this.searchFilters.sectors) || _.find(this.searchFilters.sectors, (s) => s) !== undefined);
                },
                set: function (newValue) {
                    if (newValue) {
                        this.searchFilters.sectors = {}
                    }
                }
            },
            allGeographicArea: {
                get: function () {
                    return !(!_.isEmpty(this.searchFilters.geographicArea) || _.find(this.searchFilters.geographicArea, (gA) => gA) !== undefined);
                },
                set: function (newValue) {
                    if (newValue) {
                        this.searchFilters.geographicArea = {}
                    }
                }
            },
            allTipologiaEnti: {
                get: function () {
                    return !(!_.isEmpty(this.searchFilters.tipologiaEnti) || _.find(this.searchFilters.tipologiaEnti, (gA) => gA) !== undefined);
                },
                set: function (newValue) {
                    if (newValue) {
                        this.searchFilters.tipologiaEnti = {}
                    }
                }
            },
            callsFiltered () {
                return _.chain(this.calls)
                    .filter(c => {
                        if (this.searchKeyWord !== "") {
                            return (c.name !== undefined && c.name !== null && c.name.toLowerCase().includes(this.searchKeyWord.toLowerCase()));
                        }
                        return true
                    })
                    .filter(cP => {
                        if (this.onlyFeatured) {
                            return (cP.featured === "yes")
                        }
                        return true
                    })
                    .filter(cF => {
                        if (this.allStudyTitle) {
                            return true
                        } else if (Array.isArray(cF.filters.study_title)) {
                            return _.intersection(
                                cF.filters.study_title.map(x => x && x.toLowerCase()),
                                this.selectedFiltersStudyTitle
                            ).length > 0;
                        } else {
                            return false
                        }
                    })
                    .filter(cF => {
                        if (this.allSectors) {
                            return true
                        } else if (Array.isArray(cF.filters.category)) {
                            return _.intersection(
                                cF.filters.category.map(x => x && x.toLowerCase()),
                                this.selectedFiltersCategories
                            ).length > 0;
                        } else {
                            return false
                        }
                    })
                    .filter(cF => {
                        if (this.allGeographicArea) {
                            return true
                        } else if (Array.isArray(cF.filters.region)) {
                            return _.intersection(
                                cF.filters.region.map(x => x && x.toLowerCase()),
                                this.selectedFiltersGeographicArea
                            ).length > 0;
                        } else {
                            return false
                        }
                    })
                    .filter(cF => {
                        if (this.allTipologiaEnti) {
                            return true
                        } else if (Array.isArray(cF.filters.tipologiaEnti)) {
                            return _.intersection(
                                cF.filters.tipologiaEnti.map(x => x && x.toLowerCase()),
                                this.selectedFiltersTipologiaEnti
                            ).length > 0;
                        } else {
                            return false
                        }
                    })
                    .value()
            },
            selectedFiltersStudyTitle () {
                return _.chain(Object.entries(this.searchFilters.studyTitle))
                    .filter(([key, value]) => value)
                    .map(([key, value]) => key && key.toLowerCase())
                    .value()
            },
            selectedFiltersCategories () {
                return _.chain(Object.entries(this.searchFilters.sectors))
                    .filter(([key, value]) => value)
                    .map(([key, value]) => key && key.toLowerCase())
                    .value()
            },
            selectedFiltersGeographicArea () {
                return _.chain(Object.entries(this.searchFilters.geographicArea))
                    .filter(([key, value]) => value)
                    .map(([key, value]) => key && key.toLowerCase())
                    .value()
            },
            selectedFiltersTipologiaEnti () {
                return _.chain(Object.entries(this.searchFilters.tipologiaEnti))
                    .filter(([key, value]) => value)
                    .map(([key, value]) => key && key.toLowerCase())
                    .value()
            },
            filtersCountStudyTitle () {
                return _.filter(this.searchFilters.studyTitle, (sT) => sT).length
            },
            filtersCountSectors () {
              return _.filter(this.searchFilters.sectors, (s) => s).length
            },
            filtersCountGeographicArea () {
              return _.filter(this.searchFilters.geographicArea, (gA) => gA).length
            },
            filtersCountTipologiaEnti () {
                return _.filter(this.searchFilters.tipologiaEnti, (gA) => gA).length
            }
        },
        mounted() {
            this.backToTop()
            this.getCalls()
            $('#modalCallsDetail').on('hidden.bs.modal', () => {
                this.callDetail = {};
            })
        },
        methods: {
            backToTop () {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            },
            getCalls () {
                this.$emit('is-components-ready', 'calls', false)
                bandiClient.getBandi()
                    .then((res) => {
                        this.calls = _.orderBy(
                            _.filter(
                                _.map(res.events, (e) => this.buildAndFormatCall(e)), (cF) => {
                                        return cF.endDate >= moment().unix()
                                }),
                            ['startDate', 'endDate', 'name'], ['desc', 'desc', 'asc']
                        )
                        this.init()
                    })
                    .catch(() => {
                        this.showModalErrorBlocker('calls.modale.errore.bandi')
                    })
            },
            buildAndFormatCall (event) {
                return {
                    id: uuidv4(),
                    startDate: event.start,
                    endDate: event.end,
                    name: event.name,
                    location_name: event.location_name,
                    featured: event.featured,
                    image_url: event.image_url,
                    details: event.details,
                    organizer_name: event.organier_name,
                    manual: event.customfield_1,
                    study_group: event.customfield_3,
                    telegram_study_group: event.customfield_15,
                    online_curse: event.customfield_4,
                    update: event.customfield_5,
                    bando: event.customfield_7,
                    guide_partecipation: event.customfield_8,
                    guide_study: event.customfield_9,
                    offical_info: event.customfield_10,
                    study_title: event.customfield_14,
                    filters: {
                        study_title: (event.event_type_4 !== undefined && event.event_type_4 !== null) ? Object.values(event.event_type_4) : event.event_type_4,
                        category: (event.event_type_3 !== undefined && event.event_type_3 !== null) ? this.formatCategories(Object.values(event.event_type_3)) : event.event_type_3,
                        region: (event.event_type_2 !== undefined && event.event_type_2 !== null) ? Object.values(event.event_type_2) : event.event_type_2,
                        tipologiaEnti: (event.event_type_5 !== undefined && event.event_type_5 !== null) ? Object.values(event.event_type_5) : event.event_type_5
                    },
                    timer: undefined
                }
            },
            formatData (data, format) {
                return moment.unix(data).locale('it').format(format)
            },
            openCallDetail (row) {
                this.callDetail = row
                const endDateTime = row.endDate - moment().unix()
                if (endDateTime > 0) {
                    this.timer = new Timer();
                    this.timer.start({precision: 'seconds', countdown: true, startValues: {seconds: endDateTime}});
                    this.setEndDateCallString()

                    if (this.timer.isRunning()) {
                        this.timer.addEventListener('secondsUpdated', (e) => {
                            this.setEndDateCallString()
                        });
                        this.timer.addEventListener('targetAchieved', (e) => {
                            this.stopTimer()
                            this.competitionEnd = ''
                        });
                        this.$nextTick(() => {
                            $('#modalCallsDetail').modal('show')
                            $('#modalCallsDetail').on('hidden.bs.modal', (event) => {
                                this.stopTimer()
                                this.competitionEnd = ''
                            })
                        })
                    } else {
                        $('#modalCallsDetail').modal('show')
                        this.competitionEnd = ''
                    }
                } else {
                    $('#modalCallsDetail').modal('show')
                }
            },
            showModalErrorBlocker (message) {
                const context = this
                context.$emit('is-components-ready', 'calls', true)
                $('#modalCallsRequestsError').modal({backdrop: 'static', keyboard: false})
                $('#message-body-calls-requests-error').html(context.$t(message))
            },
            getImagePath (image) {
                return require("../assets/images/new-image/" + image)
            },
            openLink(link) {
                window.open(link,'_blank','noopener')
            },
            changeBorderColorDelete (color) {
                $('#delete-search-icon').css('border', '1px solid ' + color)
            },
            stopTimer () {
                if (this.timer !== undefined) {
                    this.timer.stop()
                    this.timer.removeEventListener('secondsUpdated')
                    this.timer.removeEventListener('targetAchieved')
                }
            },
            formatCategories (categories) {
                return _.map(categories, (c) => {
                    c = c.replace("Settore ", "")
                    return c
                })
            },
            init () {
                const context = this
                $(document).ready(() => {
                    $('.dropdown-menu').on('click', function(event) {
                        event.stopPropagation();
                    });

                    context.$emit('is-components-ready', 'calls', true)
                })
            },
            setEndDateCallString () {
                this.competitionEnd = this.timer.getTimeValues().days + " "
                    + this.$t('calls.giorni') + " "
                    + this.timer.getTimeValues().hours + "h "
                    + this.timer.getTimeValues().minutes + "m "
                    + this.timer.getTimeValues().seconds + "s"
            },
            triggerCheckbox (type, name) {
                if (type !== 'allGeographicArea' && type !== 'allStudyTitle' && type !== 'allSectors' && type !== 'tipologiaEnti') {
                    const searchFilters = _.cloneDeep(this.searchFilters)
                    searchFilters[type][name] = !(searchFilters[type][name] !== undefined && searchFilters[type][name]);
                    this.searchFilters = searchFilters
                } else {
                    if (type === 'allGeographicArea') {
                        this.allGeographicArea = !this.allGeographicArea
                    } else if (type === 'allStudyTitle') {
                        this.allStudyTitle = !this.allStudyTitle
                    }  else if (type === 'allTipologiaEnti') {
                        this.allTipologiaEnti = !this.allTipologiaEnti
                    } else {
                        this.allSectors = !this.allSectors
                    }
                }
            },
            updateDropdownAvailableSpace() {
                try {
                    const h = $('footer').offset().top - $('#filters-badge-row').offset().top;
                    this.dropdownAvailableSpace = `${h - 5}px`;
                } catch (e) {
                    this.dropdownAvailableSpace = "unset"
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-dark {
        .custom-text-filters {
            display: inline-flex;
            padding: .25em .4em;
            font-size: 75%;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: .25rem;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }
    }

    html.theme-light {
        .custom-text-filters {
            color: #343a40 !important;
            display: inline-flex;
            padding: .25em .4em;
            font-size: 75%;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: .25rem;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }
    }

    html.theme-light, html.theme-dark {
        .font-icon {
            font-size: 30px;
        }

        .border-right-1-grey {
            border-right: 1px solid grey;
        }

        .rounded-border {
            border-radius: 50%;
            border: 1px solid;
        }

        .content-image {
            border-radius: 50%;
            display: inline-block;
            width: 60px;
            height: 60px;
        }

        .switch {
            height: 24px;
            width: 50px;
            bottom: .13rem !important;
        }

        .slider:before {
            height: 16px;
            width: 16px;
        }

        input[type="checkbox"][readonly] {
            pointer-events: none;
        }
    }
</style>
